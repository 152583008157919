// Track maps
import Bahrain from "../../../../assets/images/tracks/bahrain.svg"
import Jeddah from "../../../../assets/images/tracks/jeddah.svg"
import AlbertPark from "../../../../assets/images/tracks/albert_park.svg"
import Suzuka from "../../../../assets/images/tracks/suzuka.svg"
import Shanghai from "../../../../assets/images/tracks/shanghai.svg"
import Miami from "../../../../assets/images/tracks/miami_international_autodrome.svg"
import Imola from "../../../../assets/images/tracks/autodromo_enzo_e_dino_ferrari.svg"
import Monaco from "../../../../assets/images/tracks/circuit_de_monaco.svg"
import Canada from "../../../../assets/images/tracks/circuit_gilles_villeneuve.svg"
import Spain from "../../../../assets/images/tracks/circuit_de_barcelona-catalunya.svg"
import Austria from "../../../../assets/images/tracks/red_bull_ring.svg"
import GreatBritain from "../../../../assets/images/tracks/silverstone.svg"
import Hungary from "../../../../assets/images/tracks/hungaroring.svg"
import Spa from "../../../../assets/images/tracks/spa.svg"
import Zandvoort from "../../../../assets/images/tracks/zandvoort.svg"
import Monza from "../../../../assets/images/tracks/monza.svg"
import Baku from "../../../../assets/images/tracks/baku.svg"
import AbuDhabi from "../../../../assets/images/tracks/abu-dhabi.svg"
import Brazil from "../../../../assets/images/tracks/brazil.svg"
import COTA from "../../../../assets/images/tracks/cota.svg"
import LasVegas from "../../../../assets/images/tracks/las-vegas.svg"
import Mexico from "../../../../assets/images/tracks/mexico-city.svg"
import Qatar from "../../../../assets/images/tracks/qatar.svg"
import Singapore from "../../../../assets/images/tracks/singapore.svg"

// Return an object for calling the tracks
export const Tracks = {
    "Bahrain International Circuit": Bahrain,
    "Jeddah Corniche Circuit": Jeddah,
    "Albert Park Circuit": AlbertPark,
    "Suzuka International Racing Course": Suzuka,
    "Shanghai International Circuit": Shanghai,
    "Miami International Autodrome": Miami,
    "Autodromo Enzo e Dino Ferrari": Imola,
    "Circuit de Monaco": Monaco,
    "Circuit Gilles-Villeneuve": Canada,
    "Circuit de Barcelona-Catalunya": Spain,
    "Red Bull Ring": Austria,
    "Silverstone Circuit": GreatBritain,
    Hungaroring: Hungary,
    "Circuit de Spa-Francorchamps": Spa,
    "Circuit Zandvoort": Zandvoort,
    "Autodromo Nazionale Monza": Monza,
    "Baku City Circuit": Baku,
    "Yas Marina Circuit": AbuDhabi,
    "Autódromo José Carlos Pace": Brazil,
    "Circuit of The Americas": COTA,
    "Las Vegas Street Circuit": LasVegas,
    "Autódromo Hermanos Rodríguez": Mexico,
    "Losail International Circuit": Qatar,
    "Marina Bay Street Circuit": Singapore,
}
