import styles from "./pit-crew.module.scss"
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

// Bios
import bios from "./assets/bios.json"
import { pictures } from "./assets/pictures/pictures"

// Images
import PitCrewImage from "../../assets/images/graphics/pit_crew.png"
import SprayLine from "../../assets/images/textures/spray_line_03.png"
import ModalBackground from "../../assets/images/textures/bio_background.png"
import SprayLines from "../../assets/images/textures/picture_scribble.png"
import PictureBackground from "../../assets/images/textures/image_background.png"
import NameCircle from "../../assets/images/textures/name_circle.png"
import Sticker from "../../assets/images/icons/sticker_yellow.png"
import OpenIcon from "../../assets/images/icons/open_large.png"
import Close from "../../assets/images/icons/close.png"

// Returns the HTML markup for the pit crew slide
export default function PitCrew() {
    const [modalToggled, setModalToggled] = useState(false)
    const [activeProfile, setActiveProfile] = useState(null)

    // Setup a reference to the navigation
    const navigate = useNavigate()

    // When the modalToggled state is changed
    useEffect(() => {
        if (!modalToggled) {
            setTimeout(() => {
                setActiveProfile(null)
            }, 350)
        }
    }, [modalToggled])

    return (
        <>
            <div className={styles.background} style={{ backgroundImage: `url(${PitCrewImage})` }}>
                <div
                    className={styles.close}
                    style={{ backgroundImage: `url("${Close}")` }}
                    onClick={() => navigate("/")}
                />

                <div className={styles.container}>
                    <h1 style={{ backgroundImage: `url("${SprayLine}")` }}>Our Pit Crew</h1>
                </div>

                <div className={styles.personToggles}>
                    {bios.map((bio, index) => (
                        <div
                            key={index}
                            className={styles.toggle}
                            style={{
                                backgroundImage: `url(${OpenIcon})`,
                                left: `${bio.position.left}vw`,
                                top: `${bio.position.top}vh`,
                            }}
                            onClick={() => {
                                setModalToggled(true)
                                setActiveProfile(bio)
                            }}
                        />
                    ))}
                </div>
            </div>

            <div
                className={[styles.modal, modalToggled ? styles.isToggled : ""].join(" ")}
                onClick={() => setModalToggled(false)}
            >
                <div className={styles.card}>
                    <img src={ModalBackground} className={styles.graphic} />
                    <img src={Sticker} className={styles.sticker} />

                    <div className={styles.grid}>
                        <div
                            className={styles.imageContainer}
                            style={{ backgroundImage: `url(${SprayLines})` }}
                        >
                            <div
                                className={styles.pictureProfile}
                                style={{ backgroundImage: `url(${PictureBackground})` }}
                            >
                                <div
                                    className={styles.profilePictureInner}
                                    style={{
                                        backgroundImage: `url(${pictures[activeProfile?.image]})`,
                                    }}
                                />
                            </div>
                        </div>

                        <div className={styles.textContainer}>
                            <div
                                className={styles.name}
                                style={{ backgroundImage: `url(${NameCircle})` }}
                            >
                                <p>{activeProfile?.name}</p>
                            </div>

                            <div className={styles.bio}>
                                <p>
                                    Position In Pit
                                    <br />
                                    <span>{activeProfile?.pit_position}</span>
                                </p>

                                {activeProfile?.fav_circuit && (
                                    <p>
                                        Favourite Circuit:
                                        <br />
                                        <span>{activeProfile?.fav_circuit}</span>
                                    </p>
                                )}

                                {activeProfile?.fav_bull && (
                                    <p>
                                        Favourite Red Bull:
                                        <br />
                                        <span>{activeProfile?.fav_bull}</span>
                                    </p>
                                )}

                                {activeProfile?.fav_moment && (
                                    <p>
                                        Favourite Moment:
                                        <br />
                                        <span>{activeProfile?.fav_moment}</span>
                                    </p>
                                )}

                                {activeProfile?.fav_tune && (
                                    <p>
                                        Favourite Tune:
                                        <br />
                                        <span>{activeProfile?.fav_tune}</span>
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
