import JonathanWheatley from "./JonathanWheatley.jpg"
import AdrianNewey from "./AdrianNewey.jpg"
import ChristianHorner from "./ChristianHorner.jpg"
import WillCourtenay from "./WillCourtney.jpg"
import HannahSchmitz from "./HannahSchmitz.jpg"
import StevenKnowles from "./StevenKnowles.jpg"
import GianPieroLambiase from "./GianPieroLambiase.jpg"
import HughBird from "./HughBird.jpg"

export const pictures = {
    JonathanWheatley,
    AdrianNewey,
    ChristianHorner,
    WillCourtenay,
    HannahSchmitz,
    StevenKnowles,
    GianPieroLambiase,
    HughBird,
}
