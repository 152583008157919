import Translations from "../assets/data/translations.json"

// Take in a key and language and return the translation
export const printTranslation = (key, lang, defaultString) => {
    if (!key || !lang) return defaultString

    // Get the language "translations" array from the translations
    const langGroup = Translations.find((t) => t.lang === lang)

    // If there are no translations found, return the english default
    if (!langGroup || langGroup.length === 0) return defaultString

    // Use the key to find the translation
    const translation = langGroup?.translations?.find((t) => t.key === key)

    // Return either the translation (if it's available) or the default string
    return translation?.text || defaultString
}
