// Utility functions
import { renderServerBase, renderServerKey } from "./functions"

// Server endpoints
const serverBase = renderServerBase()
const serverKey = renderServerKey()

// Store an array of requests to process and an ID to keep track of the interval
let offlineRequests = []
let intervalID = null

// Called when the application is loaded and authenticated
export const checkOfflineRequests = async () => {
    // Check for any requests still be stored locally
    if (localStorage.getItem("RBR_RG_Requests")) {
        // Parse them and set them into the array object
        offlineRequests = JSON.parse(localStorage.getItem("RBR_RG_Requests")) || []

        // Attempt to process any requests found
        if (offlineRequests.length > 0) {
            processOfflineRequests()
        }
    }
}

// Process the stack of offline requests
export const processOfflineRequests = async () => {
    // Start the interval if it's not already running
    if (!intervalID) {
        intervalID = setInterval(() => {
            // Check if there's a network connection
            if (navigator.onLine) {
                // Clear the interval
                clearInterval(intervalID)
                intervalID = null

                // Process the requests in the queue
                while (offlineRequests.length > 0) {
                    // Get the first element from the array
                    const processRequest = offlineRequests.shift()

                    // Set the updated object back into local storage
                    localStorage.setItem("RBR_RG_Requests", JSON.stringify(offlineRequests))

                    // And then process the request we lifted
                    safeRequest(processRequest.method, processRequest.endpoint, processRequest.body)
                }
            }
        }, 2000)
    }
}

// Middleware function to attempt requests into the database, otherwise saves locally to retry
export const safeRequest = async (method = "GET", endpoint, body = {}) => {
    return new Promise((res, rej) => {
        // Check to see if there is a network connection
        if (navigator.onLine) {
            // Store some options for the request
            let requestOptions = {
                method,
                headers: {
                    "Content-Type": "application/json",
                    APIKey: serverKey,
                },
            }

            // If the method is not GET, attach any body there might be
            if (method !== "GET") {
                requestOptions.body = JSON.stringify(body)
            }

            // Attempt to make the request
            fetch(`${serverBase}${endpoint}`, requestOptions)
                .then((response) => {
                    // Return the response as JSON
                    return response.json()
                })
                .then((data) => {
                    // Return the data back to the request
                    res(data)
                })
                .catch((err) => {
                    console.error(err)
                })
        } else {
            // If the client is not online, store the request locally
            const newRequest = { method, endpoint, body }

            // Push the new request onto the offline stack to be processed
            offlineRequests.push(newRequest)

            // Then set this back into the local storage on the client
            localStorage.setItem("RBR_RG_Requests", JSON.stringify(offlineRequests))

            // Then attempt to start the offline interval check
            processOfflineRequests()

            // Close the promise out
            res()
        }
    })
}

// Attempt to fetch from the server, or return from cache
export const safeDataFetch = async (method = "GET", endpoint, body = {}, key) => {
    return new Promise((res, rej) => {
        // Is the client online?
        if (navigator.onLine) {
            // Store some options for the request
            let requestOptions = {
                method,
                headers: {
                    "Content-Type": "application/json",
                    APIKey: serverKey,
                },
            }

            // If the method is not GET, attach any body there might be
            if (method !== "GET") {
                requestOptions.body = JSON.stringify(body)
            }

            // Attempt to make the request
            fetch(`${serverBase}${endpoint}`, requestOptions)
                .then((response) => {
                    // Return the response as JSON
                    return response.json()
                })
                .then((data) => {
                    // Write then JSON response into the local storage
                    localStorage.setItem(`RBR_RG_${key}`, JSON.stringify(data))

                    // Return the data back to the request
                    res(data)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            // If they are not online, find the matching key in the local storage cache
            const localCache = JSON.parse(localStorage.getItem(`RBR_RG_${key}`)) || {}

            // Return this instead
            res(localCache)
        }
    })
}
