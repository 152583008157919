import React from "react"
import { createRoot } from "react-dom/client"
import "./index.scss"

// Import the service worker setup
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

// Application entry file
import ApplicationEntry from "./entry/entry.js"

// Use the root component as the container
const container = document.getElementById("root")

// Create a new root to mount React onto
const root = createRoot(container)

// Render the app
root.render(
    <React.StrictMode>
        <ApplicationEntry />
    </React.StrictMode>
)

// Register the service worker for offline support
serviceWorkerRegistration.register()
