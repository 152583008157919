import styles from "./rb20.module.scss"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

// Images
import Background from "../../assets/images/backgrounds/rb20_background.png"
import Close from "../../assets/images/icons/close.png"

// UI components
import Touchpoint from "./touchpoint/touchpoint"

// Returns the HTML markup for the ops room slide
export default function RB20() {
    const [active, setActive] = useState(null)

    // Setup a reference to the navigation
    const navigate = useNavigate()

    return (
        <>
            <div
                className={styles.close}
                style={{ backgroundImage: `url("${Close}")` }}
                onClick={() => navigate("/")}
            />

            <div className={styles.container} style={{ backgroundImage: `url("${Background}")` }}>
                <Touchpoint
                    id="frontWing"
                    translationKey="FrontWingAndNoseCone"
                    title="Front wing & Nose cone"
                    content="The 4-level front wing reduces the amount of dirty air which the car pushes outwards. Dirty air can cause significant overtaking issues and stability. The front wing can be adjusted up to 40mm during a session to change airflow, look out for this during a pit stop! The nose cone stores our driver drinks vessel and can hold ballast to improve weight balance."
                    active={active}
                    setActive={setActive}
                />
                <Touchpoint
                    id="frontSuspension"
                    translationKey="FrontSuspension"
                    title="Front Suspension"
                    content="Suspension systems allow the car to remain stable on uneven track surfaces or over kerbs. The front of the RB20 maintains a pull-rod geometry which means the suspension rod is fitted from the top of wheel, downwards into the nose where it pulls against internal suspension mechanisms (rockers, torsion bars and dampers), in turn absorbing shocks. Suspension set up impact’s chassis build, tire degradation, handing and grip."
                    active={active}
                    setActive={setActive}
                />
                <Touchpoint
                    id="halo"
                    translationKey="Halo"
                    title="Halo"
                    content="Introduced in 2018 the titanium roll hoop or ‘halo’ can sustain forces from all directions, withstanding 15g of impact and 116kN vertically downwards (12 metric tons or 1 double decker bus). The Halo improves chance of survival by 17%."
                    active={active}
                    setActive={setActive}
                />
                <Touchpoint
                    id="floor"
                    translationKey="Floor"
                    title="Floor"
                    content="Floor design is crucial to the RB20's performance due to the re-introduction of ground effect aerodynamics in 2022. Pressure under the car generates downforce therefore circuit geometry determines which floor design we use. There are two tunnels within the floor which direct airflow from the front wing to the rear diffuser."
                    active={active}
                    setActive={setActive}
                />
                <Touchpoint
                    id="coolingSidePods"
                    translationKey="CoolingAndSidePods"
                    title="Cooling &amp; Side Pods"
                    content="Side pods cool internal components of the car and guide front wheel airflow towards to rear wing. Depending on the circuit conditions different side pods will be installed on the car to funnel airflow towards to radiators. Look out for changes in the size of the air intakes and cooling slats. Side pods also play an important role in vehicle aerodynamics, downforce capabilities and housing side impact structures."
                    active={active}
                    setActive={setActive}
                />
                <Touchpoint
                    id="rearWing"
                    translationKey="RearWingAndDiffuser"
                    title="Rear Wing &amp; Diffuser"
                    content="Dirty air causes instability to both our car and the following car. The curved structure removes the need for rear wing end plates which in turn reduces the volume of turbulent, hot air behind our car. The airflow is collected from the wake of the rear diffuser by the rear wing and is pushed high into the air."
                    active={active}
                    setActive={setActive}
                />
                <Touchpoint
                    id="rearSuspension"
                    translationKey="RearSuspension"
                    title="Rear Suspension"
                    content="Suspension systems allow the car to remain stable on uneven track surfaces or over kerbs. The rear of the RB20 maintains a push-rod geometry which means the suspension rod is fitted from the bottom of wheel, upwards into the nose where it pushes against internal suspension mechanisms (rockers, torsion bars and dampers), in turn absorbing shocks. Suspension set up impact’s chassis build, tire degradation, handing and grip."
                    active={active}
                    setActive={setActive}
                />

                <div className={styles.reset} onClick={() => setActive(null)} />
            </div>
        </>
    )
}
