import styles from "./home.module.scss"
import { useNavigate } from "react-router-dom"

// Overlay textures
import SprayCanTop from "../../assets/images/textures/spray_can_top.png"
import ScanMiddle from "../../assets/images/textures/scan_middle.png"
import SprayCanLower from "../../assets/images/textures/spray_can_lower.png"
import BorderVertical from "../../assets/images/textures/border_vertical.png"
import BorderHoriz from "../../assets/images/textures/border_horiz.png"

// Card backgrounds
import RaceHub from "../../assets/images/backgrounds/race_hub.png"
import PitCrew from "../../assets/images/backgrounds/pit_crew.png"
import PitWall from "../../assets/images/backgrounds/pit_wall.png"
import RB20 from "../../assets/images/backgrounds/rb20.png"

// Spray paint textures
import SprayCircle from "../../assets/images/textures/spray_circle.png"
import SprayCircle02 from "../../assets/images/textures/spray_circle_02.png"
import SprayScribble from "../../assets/images/textures/spray_scribble.png"
import SprayZig from "../../assets/images/textures/spray_zig.png"

// Main entry page for the application
export default function Home() {
    // Setup an instance to the router navigator
    const navigate = useNavigate()

    return (
        <div className={styles.container}>
            {/* Textures */}
            <img src={SprayCanTop} alt="Upper Spray Can Texture" />
            <img src={ScanMiddle} alt="Central Scan Texture" />
            <img src={SprayCanLower} alt="Lower Spray Can Texture" />
            {/* Borders */}
            <img src={BorderVertical} alt="Vertical Border" />
            <img src={BorderHoriz} alt="Horizontal Border" />

            <div className={styles.cards}>
                <div
                    className={styles.card}
                    style={{ backgroundImage: `url("${RaceHub}")` }}
                    onClick={() => navigate("/race-hub")}
                >
                    <div className={styles.content}>
                        <div
                            className={styles.title}
                            style={{ backgroundImage: `url("${SprayCircle02}")` }}
                        >
                            <h1>Race Hub</h1>
                        </div>

                        <div className={styles.button}>Explore</div>
                    </div>
                </div>
                <div
                    className={styles.card}
                    style={{ backgroundImage: `url("${PitCrew}")` }}
                    onClick={() => navigate("/pit-crew")}
                >
                    <div className={styles.content}>
                        <div
                            className={styles.title}
                            style={{ backgroundImage: `url("${SprayZig}")` }}
                        >
                            <h1>Pit Crew</h1>
                        </div>

                        <div className={styles.button}>Explore</div>
                    </div>
                </div>
                <div
                    className={styles.card}
                    style={{ backgroundImage: `url("${PitWall}")` }}
                    onClick={() => navigate("/race-support")}
                >
                    <div className={styles.content}>
                        <div
                            className={styles.title}
                            style={{ backgroundImage: `url("${SprayScribble}")` }}
                        >
                            <h1>Race Support</h1>
                        </div>

                        <div className={styles.button}>Explore</div>
                    </div>
                </div>
                <div
                    className={styles.card}
                    style={{ backgroundImage: `url("${RB20}")` }}
                    onClick={() => navigate("/rb20")}
                >
                    <div className={styles.content}>
                        <div
                            className={styles.title}
                            style={{ backgroundImage: `url("${SprayCircle}")` }}
                        >
                            <h1>RB20</h1>
                        </div>

                        <div className={styles.button}>Explore</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
