import styles from "./loading.module.scss"

// UI components
import { CosmosSpinner, CosmosText } from "@cosmos/web/react"

// Returns a full-screen loading screen, which fades out when loading is false
export default function Loading({ loading }) {
    return (
        <div className={[styles.container, !loading ? styles.isHidden : ""].join(" ").trim()}>
            {/* <CosmosText size="small">Warming the tyres...</CosmosText> */}
            {/* <CosmosSpinner /> */}
        </div>
    )
}
