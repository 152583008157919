import styles from "./background.module.scss"
import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"

// UI components
import SprayGraphic from "../../assets/images/textures/background_spray.png"
import DotsOverlay from "../../assets/images/textures/dots_overlay.png"
import VerticalGrid from "../../assets/images/textures/vertical_grid.png"
import Close from "../../assets/images/icons/close.png"

// Returns the background component
export default function Background({ children, scroll }) {
    // Setup a reference to the navigation
    const navigate = useNavigate()

    // Div refs
    const sprayRef = useRef(null)
    const dotsRef = useRef(null)
    const gridRef = useRef(null)
    const gridRefRight = useRef(null)

    useEffect(() => {
        // If the refs are not available, return
        if (!sprayRef.current || !dotsRef.current) return

        // Move the backgorund graphics at different speeds
        sprayRef.current.style.transform = "translateX(" + scroll * -0.05 + "px)"
        dotsRef.current.style.transform = "translateX(" + scroll * -0.025 + "px)"
        gridRef.current.style.transform = "translateX(" + scroll * -0.1 + "px)"
        gridRefRight.current.style.transform = "translateX(" + scroll * -0.055 + "px)"
    }, [scroll])

    return (
        <div className={styles.wrapper}>
            <div
                className={styles.burger}
                style={{ backgroundImage: `url("${Close}")` }}
                onClick={() => navigate("/")}
            />

            <div
                ref={sprayRef}
                className={styles.spray}
                style={{ backgroundImage: `url('${SprayGraphic}')` }}
            />

            <div
                ref={dotsRef}
                className={styles.dots}
                style={{ backgroundImage: `url('${DotsOverlay}')` }}
            />

            <div
                ref={gridRef}
                className={styles.sideCans}
                style={{ backgroundImage: `url('${VerticalGrid}')` }}
            />

            <div
                ref={gridRefRight}
                className={styles.sideCansRight}
                style={{ backgroundImage: `url('${VerticalGrid}')` }}
            />

            {children}
        </div>
    )
}
