import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import { useContext } from "react"
import { AppContext } from "./context"

// Utility functions
import { checkOfflineRequests } from "./database"

// UI components
import { CosmosFlag, CosmosOption, CosmosSelect, CosmosText } from "@cosmos/web/react"

// Page imports
import Home from "../pages/home/home"
import RaceHub from "src/pages/race-hub/race-hub"
import RB20 from "src/pages/rb20/rb20"
import PitCrew from "src/pages/pit-crew/pit-crew"
import RaceSupport from "src/pages/race-support/race-support"

// Returns the functionality for the app routing
export default function AppRouter({}) {
    // Attempt to process any offline requests that are remaining
    checkOfflineRequests()

    // Get the current location
    const { pathname } = useLocation()

    // Get the language from the context
    const { lang, setLang } = useContext(AppContext)

    return (
        <>
            <Routes>
                {/* Application routes */}
                <Route path="/" element={<Home />} />
                <Route path="/race-hub" element={<RaceHub />} />
                <Route path="/race-support" element={<RaceSupport />} />
                <Route path="/rb20" element={<RB20 />} />
                <Route path="/pit-crew" element={<PitCrew />} />

                {/* Redirect all routes that don't match through to the homepage */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>

            {["/race-hub", "/rb20"].includes(pathname) && (
                <div className="lang_chooser">
                    <CosmosSelect
                        appearance="light"
                        onInputchange={({ detail }) => setLang(detail.value)}
                    >
                        <CosmosOption appearance="light" value="EN" selected={lang === "EN"}>
                            <CosmosText className="input_flex">
                                <CosmosFlag code="GB" />
                                English
                            </CosmosText>
                        </CosmosOption>
                        <CosmosOption appearance="light" value="DE" selected={lang === "DE"}>
                            <CosmosText className="input_flex">
                                <CosmosFlag code="DE" />
                                Deutsch
                            </CosmosText>
                        </CosmosOption>
                        <CosmosOption appearance="light" value="JP" selected={lang === "JP"}>
                            <CosmosText className="input_flex">
                                <CosmosFlag code="JP" />
                                日本語
                            </CosmosText>
                        </CosmosOption>
                    </CosmosSelect>
                </div>
            )}
        </>
    )
}
