// Return a base URL for accessing the server endpoints
export const renderServerBase = () => {
	// Get the hostname of the current request
	const currentHost = window.location.hostname

	// Check the environment we're currently running in
	if (currentHost === 'garage-race-guides.herokyapp.com' || currentHost === 'www.garage-race-guides.herokyapp.com') {
		// Production host
		return 'https://garage-race-guides.herokyapp.com/api'
	} else {
		// Anything else, process on the staging host
		return 'https://trackside-hospitality-stage.herokuapp.com/api'
	}
}

// Returns the relevant API key for the environment
export const renderServerKey = () => {
	// Get the hostname of the current request
	const currentHost = window.location.hostname

	// Check the environment we're currently running in
	if (currentHost === 'garage-race-guides.herokyapp.com' || currentHost === 'www.garage-race-guides.herokyapp.com') {
		// Production key
		return 'API_KEY'
	} else {
		// Stating key
		return 'API_KEY'
	}
}