import { useEffect, useState, useRef, createContext } from "react"
import moment from "moment"

// UI components
import Loading from "../components/loading/loading"
import { CosmosMode } from "@cosmos/web/react"

// Create a new global context
export const AppContext = createContext()

// Export the children, wrapped in the app context
export const AppProvider = ({ children }) => {
    // Context states
    const [loading, setLoading] = useState(true)
    const [event, setEvent] = useState(null)
    const [standings, setStandings] = useState(null)
    const [lang, setLang] = useState("EN")

    // Server status and pending requests
    const [network, setNetwork] = useState({
        online: true,
        pendingRequests: 0,
    })

    // App version
    const appVersion = "2.2.0"

    // Keep a reference of the pending requests while offine
    const pendingRequests = useRef(0)

    // On context mount
    useEffect(() => {
        // Fetch the pending requests
        const offlineRequests = JSON.parse(localStorage.getItem("RBR_RG_Requests")) || []

        // Set the language from the local storage
        setLang(localStorage.getItem("RBR_RG_Lang") || "EN")

        // Run the initial checks
        setNetwork((network) => ({
            ...network,
            online: navigator.onLine,
            pendingRequests: offlineRequests.length,
        }))

        // Then add some event listeners for network changes
        window.addEventListener("online", function () {
            setNetwork((network) => ({ ...network, online: true }))
        })

        window.addEventListener("offline", function () {
            setNetwork((network) => ({ ...network, online: false }))
        })

        // Run through the preflight checks for the app to work
        syncService()
        syncServiceStandings()

        // Then remove the listeners on context unmount
        return () => {
            window.removeEventListener("online", null)
            window.removeEventListener("offline", null)
        }
    }, [])

    useEffect(() => {
        // Set the language into the local storage
        localStorage.setItem("RBR_RG_Lang", lang)
    }, [lang])

    // Setup a function for running through sync/preflight updates
    const syncService = async () => {
        // Setup a variable for storing the events
        let events

        // First check to see if we're online
        if (navigator.onLine) {
            // Get the current year
            const currentYear = moment.utc().format("YYYY")

            // Get an updated events listing from pitwall
            events = await fetch(
                `https://pitwall.redbullracing.com/api/events/season/${currentYear}?includeSessions=true`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": "7303c8ef-d91a-4964-a7e7-78c26ee17ec4",
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    // Update the last synced time
                    localStorage.setItem("RBR_RG_LastSynced", moment.utc().format())

                    // Return the data
                    return data
                })
                .catch((err) => {
                    console.error(err)

                    // Attempt to fetch the events from the local storage if there was an error
                    events = JSON.parse(localStorage.getItem(`RBR_RG_${currentYear}_Events`)) || []
                })

            // Write the events into the local storage
            localStorage.setItem(`RBR_RG_${currentYear}_Events`, JSON.stringify(events))
        } else {
            // Attempt to fetch the events from the local storage
            events = JSON.parse(localStorage.getItem(`RBR_RG_${currentYear}_Events`)) || []
        }

        // Find the current event, based on a 24 hour grace period after the events
        const findCurrentEvent = () => {
            // Get a current timestamp, minus 24 hours (os we keep it open until the event is over)
            const now = moment.utc().subtract(24, "hours")

            // Filter events to only include those that end after the current date and time
            let futureEvents = events.filter((event) => moment(event.event_end).isAfter(now))

            // Sort the future events in ascending order based on the event_end date
            futureEvents.sort((a, b) => moment(a.event_end).diff(moment(b.event_end)))

            // Return the first event from the sorted array
            return futureEvents[0]
        }

        // Find the event to show
        const activeEvent = findCurrentEvent()

        // If there is an active event
        if (activeEvent?.event_uuid) {
            // Set the event into the state
            setEvent(activeEvent)
        }

        // Update the last checked time
        localStorage.setItem("RBR_RG_LastChecked", moment.utc().format())

        // And toggle the loading state
        setLoading(false)
    }

    // Setup a function for running through sync/preflight updates
    const syncServiceStandings = async () => {
        // Setup a variable for storing the standings
        let driverStandings

        // First check to see if we're online
        if (navigator.onLine) {
            // Get the current year
            const currentYear = moment.utc().format("YYYY")

            // Get an updated events listing from pitwall
            driverStandings = await fetch(
                `https://pitwall.redbullracing.com/api/stats/drivers/${currentYear}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": "7303c8ef-d91a-4964-a7e7-78c26ee17ec4",
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    // Update the last synced time
                    localStorage.setItem("RBR_RG_LastSynced", moment.utc().format())

                    // Return the data
                    return data
                })
                .catch((err) => {
                    console.error(err)

                    // Attempt to fetch the driverStandings from the local storage if there was an error
                    driverStandings =
                        JSON.parse(localStorage.getItem(`RBR_RG_${currentYear}_Standings`)) || []
                })

            // Write the driverStandings into the local storage
            localStorage.setItem(`RBR_RG_${currentYear}_Standings`, JSON.stringify(driverStandings))

            // Set the standings into the state
            setStandings(driverStandings)
        } else {
            // Attempt to fetch the driverStandings from the local storage
            driverStandings =
                JSON.parse(localStorage.getItem(`RBR_RG_${currentYear}_Standings`)) || []

            // Set the standings into the state
            setStandings(driverStandings)
        }
    }

    // When the network object changes
    useEffect(() => {
        // If the network get set to offline
        if (!network.online) {
            // Start an interval to fetch the pending requests
            const requestCheck = setInterval(() => {
                // If the network is back online, stop processing
                if (network.online) {
                    clearInterval(requestCheck)
                    return
                }

                // Fetch the pending requests
                const offlineRequests = JSON.parse(localStorage.getItem("RBR_RG_Requests")) || []

                // Set the total count into the state
                if (pendingRequests.current !== offlineRequests.length) {
                    // Set it locally to avoid async waits
                    pendingRequests.current = offlineRequests.length

                    // Then set the figure into the state
                    setNetwork((network) => ({
                        ...network,
                        pendingRequests: offlineRequests.length,
                    }))
                }
            }, 2000)
        }
    }, [network])

    return (
        <AppContext.Provider
            value={{ appVersion, network, setNetwork, event, standings, lang, setLang }}
        >
            <CosmosMode mode="dark">
                <Loading loading={loading} />
                {children}
            </CosmosMode>
        </AppContext.Provider>
    )
}
