"use client"

import styles from "./schedule.module.scss"
import { AppContext } from "../../../../utils/context"
import { useContext } from "react"
import moment from "moment-timezone"

// Images
import SprayLine from "../../../../assets/images/textures/spray_line.png"
import Grid from "../../../../assets/images/textures/schedule_grid.png"
import GridBar from "../../../../assets/images/icons/check_bar.png"
import GridBarWide from "../../../../assets/images/icons/check_bar_wide.png"
import Logo from "../../../../assets/images/icons/season_logo.png"
import RaceCircle from "../../../../assets/images/textures/race_circle.png"

// Returns the HTML markup for the schedule slide
export default function Schedule() {
    // Pull the active event from the context
    const { event } = useContext(AppContext)

    // Return early if there's no event
    if (!event) return null

    // Pull the event sessions from the event
    const { event_sessions } = event

    // Setup an object for storing the sessions, grouped by date
    const sessionsbyDate = {}

    // Map over the event sessions and group them by date
    event_sessions.forEach((session) => {
        // Parse the date from the session
        const date = moment(session.session_times.utc.start_time).format("YYYY-MM-DD")

        // Add the session to the date group
        if (sessionsbyDate[date]) {
            sessionsbyDate[date].push(session)
        } else {
            sessionsbyDate[date] = [session]
        }
    })

    return (
        <>
            <div className={styles.container}>
                <h1 style={{ backgroundImage: `url("${SprayLine}")` }}>
                    Weekend Schedule <span>[Local Time]</span>
                </h1>

                <div className={styles.grid} style={{ backgroundImage: `url("${Grid}")` }}>
                    {Object.entries(sessionsbyDate).map(([date, sessions]) => (
                        <div key={date} className={styles.row}>
                            <div className={styles.date}>
                                <span>{moment(date, "YYYY-MM-DD").format("DD")}</span>
                                <span>{moment(date, "YYYY-MM-DD").format("MMM")}</span>
                            </div>
                            <div className={styles.space}></div>
                            {sessions.map((session) => (
                                <div
                                    key={session.session_name}
                                    className={[
                                        styles.session,
                                        session.session_abbreviation === "GP" ? styles.isRace : "",
                                    ].join(" ")}
                                >
                                    <img
                                        src={
                                            session.session_abbreviation === "GP"
                                                ? GridBarWide
                                                : GridBar
                                        }
                                    />
                                    <div>
                                        <span>{session.session_name}</span>
                                        <span>
                                            {moment
                                                .tz(
                                                    session.session_times.track.start_time,
                                                    session.session_times.track.timezone
                                                )
                                                .format("HH:mm")}{" "}
                                            -{" "}
                                            {moment
                                                .tz(
                                                    session.session_times.track.end_time,
                                                    session.session_times.track.timezone
                                                )
                                                .format("HH:mm")}
                                        </span>

                                        {session.session_abbreviation === "GP" && (
                                            <img className={styles.raceCircle} src={RaceCircle} />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}

                    <img src={Logo} alt="Season Logo" className={styles.logo} />
                </div>
            </div>
        </>
    )
}
