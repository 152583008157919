import styles from "./standings.module.scss"
import { AppContext } from "../../../../utils/context"
import { useContext } from "react"
import { CosmosText } from "@cosmos/web/react"

// Returns the HTML markup for the standings slide
export default function Standings() {
    // Pull the standings from the context
    const { standings } = useContext(AppContext)

    return (
        <div className={styles.container}>
            <h1>Standings</h1>

            <div className={styles.table}>
                {standings?.map((driver, index) => (
                    <div
                        className={[
                            styles.row,
                            driver?.season_team_name === "Oracle Red Bull Racing"
                                ? styles.highlight
                                : "",
                        ].join(" ")}
                    >
                        <CosmosText size="x-small" weight="bold">
                            {index + 1}
                        </CosmosText>
                        <CosmosText size="x-small">
                            {driver.first_name} {driver.last_name}
                        </CosmosText>
                        <CosmosText size="x-small" kind="subtle">
                            {driver.season_team_name}
                        </CosmosText>
                        <CosmosText size="x-small">{driver.season_points}</CosmosText>
                    </div>
                ))}
            </div>
        </div>
    )
}
